<!-- SuccessPage.vue -->
<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card class="text-center">
                    <v-card-title>
                        <v-icon large color="green">mdi-check-circle</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <h2>Your Payment Was Successful!</h2>
                        <p>Thank you for your purchase.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="goToApp">Go to App</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    methods: {
        goToApp() {
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>
</style>
